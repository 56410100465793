import styles from "./FooterLink.module.css";
import { Avatar, Button, Grid } from "@mui/material";
import ContactUsForm from "../../components/Footer/FooterContactPopup.js";
import {
  buttonDataAppleStore,
  buttonDataPlayStore,
  socialMediaLinks,
  handalClickgoogleplay,
  handalClickAppleStore,
} from "../Constants.js";
import { NavLink } from "react-router-dom";
import React, { useState } from "react";

function FooterLink() {
  const { button } = buttonDataPlayStore;
  const { buttons } = buttonDataAppleStore;

  const [isContactFormOpen, setContactFormOpen] = useState(false);

  const handleCloseContactForm = () => {
    setContactFormOpen(false);
  };

  const handleClick = () => {
    setContactFormOpen(true);
  };

  return (
    <>
      {isContactFormOpen === true ? (
        <ContactUsForm
          open={isContactFormOpen}
          onClose={handleCloseContactForm}
        />
      ) : (
        ""
      )}

      <Grid container className={styles.footerlinkmainwrap}>
        <Grid item xs={6} sm={4} md={4} className={styles.quicklinkwrap}>
          <span className={styles.quicklink_mainTitle}>quick Links</span>
          <ul className={styles.quickullistmain}>
            <li>
              <NavLink to="/about" className={styles.footerlinkcolor}>
                About
              </NavLink>
            </li>
            <li>
              <NavLink to="/features" className={styles.footerlinkcolor}>
                Features
              </NavLink>
            </li>
            <li>
              <NavLink to="/faqs" className={styles.footerlinkcolor}>
                FAQs
              </NavLink>
            </li>
          </ul>
        </Grid>

        <Grid item xs={6} sm={4} md={4} className={styles.supportwrap}>
          <span className={styles.support_mainTitle}>Support</span>
          <ul className={styles.supportullistmain}>
            <li>
              <NavLink to="/legal" className={styles.footerlinkcolor}>
                Terms, Condition & Policies
              </NavLink>
            </li>
            <li onClick={handleClick} className={styles.contactUs}>
              Contact
            </li>
          </ul>
        </Grid>

        <Grid item xs={6} sm={4} md={4} className={styles.socialmediawrap}>
          <span className={styles.socialmedia_mainTitle}>Social media</span>
          <ul className={styles.socialmedialistwrap}>
            {socialMediaLinks.map((linkItem, index) => (
              <div className={styles.listcontainer} key={index}>
                <img
                  alt={`data for ${linkItem.platform}`}
                  src={linkItem.image}
                  className={styles.image}
                />
                <li>
                  <a
                    href={linkItem.link}
                    target="_blank"
                    rel="noreferrer"
                    className={styles.footerlinkcolorlogo}
                  >
                    {linkItem.platform}
                  </a>
                </li>
              </div>
            ))}
          </ul>
        </Grid>
      </Grid>

      <Grid container className={styles.gridwrapbuttoncontainer}>
        <Grid
          item
          // xs={12}
          sm={6}
          md={6}
          lg={5}
          xl={4}
          className={styles.btngrid}
        >
          <Button
            sx={button.styles}
            startIcon={
              <Avatar
                className={styles[button.startIcon.className]}
                src={button.startIcon.src}
              />
            }
            onClick={handalClickgoogleplay}
            className={styles[button.className]}
          >
            <div className={styles[button.content.wrapClassName]}>
              {button.content.text.map((textItem, index) => (
                <span
                  key={index}
                  className={styles.googleplay}
                  style={textItem.style}
                >
                  <span>{textItem.content}</span>
                </span>
              ))}
            </div>
          </Button>
        </Grid>
        {/* <Grid item className={styles.space} md={0}  lg={1}/> */}
        <Grid
          item
          // xs={12}
          sm={6}
          md={6}
          lg={5}
          xl={4}
          className={styles.gridwrapbutton}
        >
          <Button
            sx={buttons.styles}
            startIcon={
              <Avatar
                className={styles[buttons.startIcon.className]}
                src={buttons.startIcon.src}
              />
            }
            onClick={handalClickAppleStore}
            className={styles[buttons.className]}
          >
            <div className={styles[buttons.content.wrapClassName]}>
              {buttons.content.text.map((textItem, index) => (
                <span
                  key={index}
                  className={styles.googleplay}
                  style={textItem.style}
                >
                  <span>{textItem.content}</span>
                </span>
              ))}
            </div>
          </Button>
        </Grid>
      </Grid>
    </>
  );
}

export default FooterLink;
