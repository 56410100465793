import rupee from "../images/rupee1.webp";
import money from "../images/money1.webp";
import share from "../images/invite1.webp";
import layers from "../images/layers1.webp";
import wallet from "../images/wallet1.webp";
import expense from "../images/income1.webp";
import appstore from "../images/appstore.png";
import desihisab from "../images/income1.webp";
import language from "../images/language1.webp";
import playstore from "../images/googleplay.webp";
import verified from "../images/verified(1)1.webp";
import instagram from "../images/instagram.png";
import facebook from "../images/facebook-app-symbol.png";
import twitter from "../images/twittericon.png";
import trackicon from "../images/ourmission-icon1.png";
import whyusicon1 from "../images/whychooseus-icon1.png";
import whyusicon2 from "../images/whychooseus-icon2.png";
import whyusicon3 from "../images/whychooseus-icon3.png";
import whyusicon4 from "../images/whychooseus-icon4.png";
import featureimg1 from "../images/feayure-icon2.png";
import featureimg2 from "../images/feayure-icon2.png";
import featureimg3 from "../images/feayure-icon3.png";
import featureimg4 from "../images/feayure-icon4.png";
import featureimg5 from "../images/feayure-icon5.png";
import featureimg6 from "../images/feayure-icon6.png";
import featureimg7 from "../images/feayure-icon7.png";
import featureimg8 from "../images/feayure-icon8.png";


// Header Page Json

export const headerTitle = {
  appTitle: {
    className: "freeapptitle",
    title: "#India ka apna Hisab!",
  },
};

export const handalClickgoogleplay = () => {
  window.open("https://play.google.com/store/apps/details?id=com.desihisab", "_blank");
};

export const handalClickAppleStore = () => {
  window.open("https://apps.apple.com/in/app/desi-hisab/id6471561568", "_blank");
};

export const headerHeading = {
  mainText: {
    className: "maintextwrap",
    text: {
      className: "textwrap",
      content: "Now Manage all your Income, Expenses, Dues, and Debts in real-time with your loved ones instantly, only on Desi Hisab!",
    },
  },
};

export const buttonDataPlayStore = {
  button: {
    styles: {
      "&:hover": {
        backgroundColor: "#000",
      },
    },
    startIcon: {
      src: playstore,
      className: "button_icon",
    },
    className: "googleplaybtn",
    content: {
      wrapClassName: "btnwrap",
      text: [
        {
          style: {
            fontWeight: "bold",
            textAlign: "left",
          },
          content: "GET IT ON",
          link: "http://plays.google.com",
        },
        {
          content: "Google Play",
        },
      ],
    },
  },
};

export const buttonDataAppleStore = {
  buttons: {
    styles: {
      "&:hover": {
        backgroundColor: "#000",
      },
    },
    startIcon: {
      src: appstore,
      className: "button_icon",
    },
    className: "applebtn",
    content: {
      wrapClassName: "btnwrap",
      text: [
        {
          style: {
            fontWeight: "bold",
          },
          content: "Download on",
        },
        {
          style: {
            textAlign: "left",
          },
          content: "APP Store",
          link: "https://apps.apple.com/in/app/desi-hisab/id6471561568",
        },
      ],
    },
  },
};

// Income/Expense Page json data

export const ExpenseData = [
  {
    id: 1,
    icon: desihisab,
    heading: "Create Hisab",
    description:
      "Initially, establishing a Hisab is essential to fully utilize the capabilities of this application. Hisabs serve various functions including tracking personal finances, managing group expenses, overseeing property rent, and more.",
  },
  {
    id: 2,
    icon: wallet,
    heading: "Add Income",
    description:
      "Add 'Income' transactions in any Hisab to manage your finances effortlessly. Each 'Income' entry increases your total balance.",
  },

  {
    id: 3,
    icon: expense,
    heading: "Add Expense",
    description:
      "You can add an Expense transaction in any Hisab. When you do, it will be deducted from your Hisab total.",
  },

  {
    id: 4,
    icon: layers,
    heading: "Keep Track of your Money",
    description:
      "You can track the total, income, expense, and due categories with invited members by clicking on the 'Detail' link.",
  },
];

// shareable expense page json data

export const shareAbleExpense = [
  {
    id: 1,
    icon: desihisab,
    heading: "Add Participant",
    description:
      "You can add friends and family from your contact list to any Hisab using the 'Add Participant' link. For users not registered on the Desi Hisab Application, you can use the 'invite' option.",
  },
  {
    id: 2,
    icon: wallet,
    heading: "Set Permission",
    description:
      "Desi Hisab allows you to set permissions—either 'Edit Hisab' or 'View Hisab'—for specific users on particular Hisab. With 'Edit Hisab' permission, participants can add income and expenses, while 'View Hisab' allows them to only view transactions.",
  },
];

// Free Fast Secure page json data

export const freeFastSecure = [
  {
    id: 1,
    icon: rupee,
    heading: "Free Access",
    description:
      "Desi Hisab application is available on both Android and iOS store in free of cost without any subscription fees or paid feature limit.",
  },
  {
    id: 2,
    icon: language,
    heading: "Efficient Operation in Low Internet Environments",
    description:
      "Desi Hisab is designed to work smoothly in low internet speed areas, ensuring reliable performance even with limited connectivity while living or traveling.",
  },
  {
    id: 3,
    icon: verified,
    heading: "End to End Encryption",
    description:
      "With end-to-end encryption, all your Hisabs, transactions, and profile data are securely protected. Only you and the participants you add to any Hisab have access to the transactions added by you and them, ensuring confidentiality even from Desi Hisab.",
  },
];

// Testimonial page json data

export const carouselContent = [
  {
    text: "Best application to keep track of your day by day expenses. I also liked the feature of sharing Hisab with friends. I am using it with my friends without any issue.",
    author: "Varun",
    rating: "5 stars",
  },
  {
    text: "I found what I was looking since long time on play store. Very simple app with only focusing on what is your income, what is your expense and what is your due with your friends. ",
    author: "Ravi",
    rating: "5 stars",
  },
  {
    text: "I am using Desi Hisab since last two months and it made my work easy by keeping track of my dues and debts with my friends on my fingertip.",
    author: "Rakesh",
    rating: "5 stars",
  },
];

// Insights reports json page data

export const insightReport = [
  {
    id: 1,
    icon: desihisab,
    heading: "Insights",
    description:
      "Application gives powerful statistics on “Detail” screen on every Hisab. You can easily get the Income, Expense, and Outstanding along with user wise spending details on numeric and graphical format.",
  },
  {
    id: 2,
    icon: wallet,
    heading: "Report",
    description:
      "You can export a report of your Income, Expense and Outstanding with date and time as a PDF format any time and share it with your loved ones on any social media platform.",
  },
];

// invite friends json page data

export const inviteFriends = [
  {
    id: 1,
    icon: share,
    heading: "Invite",
    description:
      "Invite friends and family to Desi Hisab using the 'Invite' option during the participant addition process. The application sends a download link via SMS from your mobile number.",
  },
  {
    id: 2,
    icon: money,
    heading: "Cash Reward",
    description:
      "Earn Unlimited Rewards on Desi Hisab! Simply invite friends and family from your contact list. Once they register, receive a 'Cash Reward' scratch card. Scratch to reveal the amount credited to your wallet which is transferable to your account using any UPI method.",
  },
];


export const welcometext = {
  title: "Welcome to Desi Hisab!",
  subTitle: "Your go-to app for hassle-free management of personal finances and shared expenses. Developed by SecureMetaSys Infotech Pvt. Ltd., Desi Hisab aims to simplify how you track and settle your financial transactions with friends, family, colleagues, and loved ones.",
};


//About Our Mission Section Data

export const ourmissiontext = [
  {
    id: 1,
    icon: trackicon,
    heading: "Track Dues and Debts",
    description: "Easily keep track of who owes you and who you owe.",
  },
  {
    id: 2,
    icon: expense,
    heading: "Manage Expenses",
    description:
      "Simplify splitting bills and managing shared expenses for trips, events, and everyday activities.",
  },
  {
    id: 3,
    icon: wallet,
    heading: "Monitor Incomes",
    description:
      "Track your incomes and ensure you stay on top of your financial goals.",
  },
];

//About - Why Choose Desi Hisab?
export const whychooseustext = [
  {
    id: 1,
    icon: whyusicon1,
    heading: "Real-Time Transaction Sharing",
    description:"Instantly share and update transactions with others, ensuring transparency and accuracy.",
  },
  {
    id: 2,
    icon: whyusicon2,
    heading: "User-Friendly Interface",
    description:"Designed with simplicity in mind, making financial management accessible to everyone.",
  },
  {
    id: 3,
    icon: whyusicon3,
    heading: "Secure and Private",
    description:"Your data security is our top priority. Desi Hisab uses advanced encryption to protect your financial information.",
  },
   {
    id: 4,
    icon: whyusicon4,
    heading: "Free to Use",
    description:"Desi Hisab is completely free, offering full functionality without any hidden charges.",
  },
];


//About - Get Started Today 
export const getstartedtodaytetxt = [
  {
    heading: "Get Started Today",
    description:"Whether you're planning a group outing, managing household finances, or simply keeping track of personal expenses, DesiHisab is here to help. Download our app for free and take control of your financial transactions today.",
  },
];



//About - How to Use App Text
export const howtouseapptext = [
  {
    id: 1,
    icon: desihisab,
    heading: "Create",
    description: "Initially, establishing a Hisab is essential to fully utilize the capabilities of this application. Hisabs serve various functions including tracking personal finances, managing group expenses, overseeing property rent, and more.  ",
      
  },
  {
    id: 2,
    icon: wallet,
    heading: "Add Income",
    description: "Add 'Income' transactions in any Hisab to manage your finances effortlessly. Each 'Income' entry increases your total balance.", 
    },

  {
    id: 3,
    icon: expense,
    heading: "Add Expense",
    description:
      "You can add an Expense transaction in any Hisab. When you do, it will be deducted from your Hisab total."
  }
];
 

//Features Page Text

export const featuresTitle = {
  title: "Features of Desi Hisab",
  subTitle: "Discover how Desi Hisab can simplify your financial management with its powerful features designed for ease and efficiency."
};


export const featurestext = [
  {
    id: 1,
    icon: featureimg1,
    heading: "Real-Time Transaction Sharing",
    description: "Stay connected with your friends, family, and colleagues by instantly sharing and updating transactions. Whether you're splitting bills or managing shared expenses, Desi Hisab ensures everyone stays on the same page.",
      
  },
  {
    id: 2,
    icon: featureimg2,
    heading: "Expense Tracking",
    description: "Effortlessly track your expenses, from daily purchases to group outings. Desi Hisab categorizes expenses for easy budgeting and financial planning.",
      
  },
  {
    id: 3,
    icon: featureimg3,
    heading: "User-Friendly Interface",
    description: "Desi Hisab allows users to create and manage group expenses, split bills, record due amounts, and keep track of who owes money to whom. The app provides a simple and user-friendly interface where users can easily add expenses, mark payments, and view detailed reports of their spending.",
      
  },
  {
    id: 4,
    icon: featureimg4,
    heading: "Add Participant",
    description: "You can add friends and family from your contact list to any Hisab using the 'Add Participant' link. For users not registered on the Desi Hisab Application, you can use the 'invite' option.",
      
  },
  {
    id: 5,
    icon: featureimg5,
    heading: "Customize Permissions with Desi Hisab",
    description: 'Desi Hisab allows custom permissions for your contacts. Users with "Edit Hisab" access can add, edit, and delete transactions. Transactions can be edited or deleted within 5 minutes, ensuring accuracy and flexibility.',
      
  },
  {
    id: 6,
    icon: featureimg6,
    heading: "Efficient Operation in Low Internet Environments",
    description: "Desi Hisab is designed to work smoothly in low internet speed areas, ensuring reliable performance even with limited connectivity while living or traveling.",
      
  },
  {
    id: 7,
    icon: featureimg7,
    heading: "Security and Privacy",
    description: "Your financial data is secure with Desi Hisab. We employ advanced encryption technologies to safeguard your information and ensure your privacy.",
      
  },
  {
    id: 8,
    icon: featureimg8,
    heading: "Support and Assistance",
    description: "Our dedicated support team is here to assist you. Whether you have questions, feedback, or need help, we're just a message away.",
      
  },


];





// social media links for footer

export const socialMediaLinks = [
  { platform: "Facebook", image: facebook, link: "https://www.facebook.com/desihisaab" },
  { platform: "Twitter", image: twitter, link: "#Twitter" },
  { platform: "Instagram", image: instagram, link: "https://www.instagram.com/desihisaab/" },
];
